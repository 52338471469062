import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import Keycloak from "keycloak-js";


Vue.config.productionTip = false

let initOptions = {
  url: 'https://login.prod.zooplus.net/auth', realm: 'zoo-office', clientId: 'sso-customer-historization-ui', onLoad:'login-required'
}

let keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).success((auth) => {

  if(!auth) {
    window.location.reload();
  }

  new Vue({
    store,
    render: h => h(App),
  }).$mount('#app')


  localStorage.setItem("keycloak-token", keycloak.token);
  localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);

  setInterval(() =>{
    keycloak.updateToken(70).success((refreshed)=>{
      if (refreshed) {
        localStorage.setItem("keycloak-token", keycloak.token);
      }
    }).error(()=>{
      console.log('Failed to refresh token');
    });


  }, 60000)

}).error(() =>{
  console.log("Authenticated Failed");
});